.container{
display: flex;
flex-direction: column;
align-items: center;
gap: 1.25rem;
width: 100%;
height: 100%;
overflow: auto;

&::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */

}


